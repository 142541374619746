#brand-tag{
    display: flex;
    padding: 0px 10px 10px 10px;
    margin: 20px 10px;
    margin-top:0px;
}
#tag-pfp{
    width: 150px;
}
.brand-tag-holder{
    margin: 20px 50px;
}
#tag-pfp-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#tag-text{
    margin: auto 0px;
    margin-left: 20px;
}
#tag-desc{
    border-radius: 5px;
    padding: 5px;
    max-height: 100px;
    overflow: scroll;
    margin: 10px 10px 10px 0px;
    font-size: large;
}
a{
    text-decoration: none;
    color: inherit;
}
#tag-name{
    font-size: 27px;
}

@media (max-width: 600px) {
    #tag-text{
        display: none;
        visibility: hidden;
    }
    #brand-tag{
        padding: 0px;
        margin: 20px 0px;
    }
    #tag-pfp{
        width: 110px;
    }

    #brand-tag{
        justify-content: center;
        border-bottom: none;
    }
}