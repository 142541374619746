#title1 {
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
  margin: 40px 20px 0px 20px;
  color: rgb(0, 0, 0);
}
#subtitle1 {
  font-size: 1.15em;
  font-weight: bold;
  text-align: center;
  margin: 10px 20px 20px 20px;
  color: rgb(0, 0, 0);
}
#daily-image {
  width: 200px;
  animation-name: image;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  border-radius: 5px;
}
#daily-card-text {
  font-size: 1em;
}
@keyframes daily-image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.daily-inner-card {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.1em;
  background-color: rgb(255, 255, 255);
  padding: 5px 5px 10px 5px;
  margin: 10px 20px;
  border-radius: 5px;
}

.daily-inner-card:nth-child(1) {

  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}
.daily-inner-card:nth-child(2) {

  animation-iteration-count: infinite;
  animation-delay: 0.75s;
}

.daily-inner-card:nth-child(3) {

  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.daily-inner-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
@keyframes colorful1 {
  0% {
    background-color: rgba(255, 55, 0, 0);
  }
  10% {
    background-color: rgb(255, 43, 43);
  }

  40% {
    background-color: rgba(0, 255, 166, 0);
  }
  100% {
    background-color: rgba(0, 255, 166, 0);
  }
}
@keyframes colorful2 {
  0% {
    background-color: rgba(255, 55, 0, 0);
  }
  10% {
    background-color: rgb(51, 255, 0);
  }
  40% {
    background-color: rgba(0, 255, 166, 0);
  }
  100% {
    background-color: rgba(0, 255, 166, 0);
  }
}
@keyframes colorful3 {
  0% {
    background-color: rgba(255, 55, 0, 0);
  }
  10% {
    background-color: rgb(0, 94, 255);
  }
  40% {
    background-color: rgba(0, 255, 166, 0);
  }
  100% {
    background-color: rgba(0, 255, 166, 0);
  }
}

@keyframes daily-title {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 20px;
  }
}
#daily-card {
  margin: 20px 30px;
}
#daily-posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 510px) {

  #daily-posts .card{
      width: 220px;
      .image{
        width: 220px;
        height: 275px;
      }
    
  }
  #daily-posts  .card-text {
    font-size: 1em;
  }
}
