.navbar {
  display: flex;
  background-color: #24262b;
  color: white;
  padding: 20px 100px;
}

#nav-elements{
  display: flex;
}
#nav-buttons{
  display: flex;
  justify-content: center;
}
/* Links inside the navbar */
.navbar a {
  font-size: 16px;
  color: white;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

#active-icon{
  text-decoration: underline;
  color: #c0c0c0;
}

#inactive-icon{
text-decoration: none;
}
.logo {
  font-size: 1.5em;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: italic;
  font-weight: bold;
  margin: auto; /* Horizontally center the logo */
}

@media (max-width: 620px) {
    #nav-buttons{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0px;
    }
    #active-icon{
      padding: 0px;
    }
    #inactive-icon{
      padding: 0px;
    }
    #nav-elements{

    }
    .navbar{
      padding: 10px 20px;
      padding-right: 10px;
    }
    .logo{
      font-size: x-large;
    }
    .navbar a{
      font-size: medium;
      margin: 10px 15px;
    }
}