.create-image {
  width: 400px;
}

body {
  margin: 0;
  padding: 0;
}
.images {

}
.post-form {
  display: flex;  
  justify-content: center;
}
.create-holder {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 20px;
}

.cropper {
  position: relative;
  width: 300px;
  background-color: rgb(255, 0, 0);
}

.viewer {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.output {
  position: relative;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(91, 91, 91, 0.8);
}

.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

textarea {

}
#share {
  font-style: normal;
  font-weight: 600;
  font-size: 0.7em;
}
.dev-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 30px 100px;
  background-color: rgb(216, 216, 216);
  height: 30px;
}
/* Links inside the navbar */
.dev-nav a {
  font-size: 16px;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

#create-button {
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  margin: 5px 0px;
}
#create-button:hover {
  background-color: rgb(106, 106, 106);
}
#tags-input-container-top{
  padding: .5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}
#tags-count{
  margin: 0 5px;
}
#tags-input-container-bottom{
  border: 2px solid #000;
  padding: .5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  display: flex;
  margin-top: 1em;
  min-height: 50px;
  align-items: center;
  flex-wrap: wrap;

}
#pre-tags-input-container{
  border: 2px solid #000;
  padding: .5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}
#tag-item{
  background-color: rgb(240, 240, 240);
  display: inline-block;
  padding: .5em .75em;
  margin: 0px .1em;
  border-radius: 20px;
  cursor: pointer;
}

#tag-item-clicked{
  background-color: rgb(203, 203, 203);
  display: inline-block;
  padding: .5em .75em;
  border-radius: 20px;
  outline: 2px rgb(255, 0, 0) solid;
  border: 2px rgb(255, 255, 255) solid;
  cursor: pointer;
}

#tags-input{
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;

}
#tag-item #tag-close{
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  display: inline-flex;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-left: .5em;
  font-size: 18px;
}

#tags-text2{
  cursor: pointer;
}


#cancel-button {
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  margin: 5px 0px;
}
#cancel-button:hover {
  background-color: rgb(106, 106, 106);
}

select {
  padding: 12px 20px;
  margin: 5px 10 20px 0px;
  box-sizing: border-box;
}
#category {
  max-width: 180px;
}
#sex {
  max-width: 180px;
}

#price {
  max-width: 180px;
}

@media (max-width: 760px) {
  .create-holder {
    display: block;
  }
}
