.brand-holder {
  display: flex;
  justify-content: center;
}
#intro {
  height: 300px;
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/1897886/pexels-photo-1897886.jpeg?auto=compress&cs=tinysrgb&w=800");
  background-position: -400px -200px;
  background-size: 400px;
}


#explore-text {
  margin: 20px;
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
  margin-top:30px;
}

#shop-by-tag-holder{
  display: flex;
  justify-content: center;
  
  margin: 0px 20px;
}
#shop-by-tag{
  display: flex;
  justify-content: center;
  max-width: 800px;
  flex-wrap: wrap;
}

#shop-by-tag #tag-item{
  margin: 5px;
}
#shop-apparel {
  background-image: url("https://images.pexels.com/photos/1897886/pexels-photo-1897886.jpeg?auto=compress&cs=tinysrgb&w=800");
  background-position: -400px -200px;
  background-size: 400px;
  display: flex;
  justify-content: center;
  height: 400px;
  margin: 0px 20px 50px 20px;
}

#popular-niches-title{
  font-size: 1.75em;
  text-align: center;
  font-weight: bold;
  margin: 50px 0px 20px 0px;
}

#highlight-line{
  border: 1px black solid;
  max-width: 500px;

}
#shop-apparel-text {
  margin: auto 0;
  color: white;
  font-size: x-large;
  text-decoration: underline;
}
#highlights-holder{
  display: flex;
  flex-wrap: wrap;
  margin:auto;
  justify-content: center;
  max-width: 1200px;
}
#highlight-box {
  display: flex;
  justify-content: center;
  background-color: blue;
  height: 400px;
  width: 500px;
  margin: 10px 10px;
  border-radius: 50px;
  border: 2px black solid;
}
#highlight-box-text {
  margin: auto 0;
  color: rgb(255, 255, 255);
  font-size: x-large;
  text-decoration: underline;
}

#intro-text {
  margin: auto 0;
  text-align: center;
  font-size: xx-large;
  font-weight: bolder;
  animation-name: intro-text;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  text-shadow: 4px 7px 10px black;
  color: white;
}

@keyframes intro-text {
  0% {
    text-shadow: 4px 7px 10px rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    
  }
  100% {
    text-shadow: 4px 7px 10px rgb(0, 0, 0);
    color: white;

  }
}

@media (max-width: 620px) {
  #intro {
    height: 200px;
    background-position: -400px -300px;
  }
  #intro-text {
    margin-top: 60px;
    font-size: 30px;
    padding: 0px 20px;
  }
}
