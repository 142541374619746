.profile-name {
  margin: auto 0;
  font-size: xx-large;
  font-weight: bolder;
}
.profile-link {
  font-size: larger;
  font-weight: normal;
  margin: 10px;
  font-style: italic;
}
.profile-bio {
  font-size: larger;
  font-weight: normal;
  margin: 10px 0px;
  font-style: italic;
}
.profile-middle {
  display: flex;
  justify-content: space-between;
}
#pfp {
  height: 200px;
}
.profile-buttons button{
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  margin: 20px 2.5px;
  border-radius: 30px;
}
.profile-buttons button:hover {
  background-color: rgb(106, 106, 106);
}

.profile-top {
  display: flex;
  justify-content: center;
}
.profile-headerbar {
  background-color: rgb(242, 242, 242);
  padding: 10px 50px 20px 50px;
  background-image: linear-gradient(rgb(190, 190, 190),#ffffff);
}

@media (max-width: 600px) {
    .profile-middle{
        display: block;
    }
    #pfp{
        height: 100px;
    }
    .profile-name{
      font-size: x-large;
      text-align: center;
    }
}
