.posts-holder {
  display: flex;
  justify-content: center;
}
.posts {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top:10px;
}

@media (max-width: 1100px) {
  .posts {
    max-width: 750px;
  }
}

@media (max-width: 800px) {
  .posts {
    max-width: 500px;
  }
}

@media (max-width: 510px) {
  .posts {
    max-width: 370px;
  }
}

@media (max-width: 370px) {
  .posts{
    justify-content: center;
  }
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow:hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  display: block;
  background-color: #fefefe;
  margin: 4% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 500px;
  max-height: 60vh;
  overflow: scroll;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
#filters-button {
  font-size: larger;
  cursor: pointer;
}

#filters-button:hover {
  color:#474747;
}


#top {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

#search-bar {
  display: flex;
  justify-content: left;
  margin-left: 290px;
}
#search-bar input {
  border: black 2px solid;
  border-radius: 5px;
  width: 100%;
}
.nav-button {
  background-color: rgb(80, 80, 80);
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
}
.loader-holder {
  display: flex;
  padding-top: 50px;
  justify-content: center;
  height: 70vh;
}
.loader {
  color: rgb(255, 255, 255);
  animation-name: loader;
  animation-duration: 2s;
  animation-delay: .5s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}
.nav-button:hover {
  background-color: rgb(106, 106, 106);
}

.active-button {
  background-color: rgb(80, 80, 80);
  border: black 1px solid;
  color: white;

  margin: 5px 5px;
  border-radius: 7px;
}
.inactive-button {
  background-color: #ffffff;
  border: none;
  margin: 5px 5px;
  color: black;
  border-radius: 7px;
  border: black 1.5px solid;
}
.inactive-button:hover {
  background-color: #e7e7e7;
  border: none;
  border: black 1.5px solid;
}

.gender-active-button {
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
}
.gender-inactive-button {
  background-color: #d4d4d4;
  border: none;
}
.gender-inactive-button:hover {
  background-color: #e7e7e7;
  border: none;
}

/* Styles for the slider component */
.slider {
  height: 10px;
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

/* Styles for the slider thumb */
.slider .thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #4b4b4b;
  cursor: grab;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  top: -5px;
}

/* Styles for the slider active state */
.slider .thumb.active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
#nav-buttons {
  display: flex;
  justify-content: center;
}
#prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#maxPrice {
  background-color: red;
  width: 30px;
}

#categories {
}

#slider-holder {
  margin: 0px 10px;
  min-width: 60%;
}

#slider-level {
  display: flex;
}

#slider-holder-holder {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-bottom: 10px;
}

#tags-filter-top {
  padding: 0.5em;
  border-radius: 3px;
  max-width: 500px;
  max-height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px black solid;
  border-radius: 5px;
  overflow: scroll;
  margin-bottom: 10px;
  margin-top:10px;
}
#tags-filter-top #tag-item-clicked{
  margin: 4px 3px;
}

#tags-filter-top #tag-item{
  margin: 3px 2px;
}
#filter-label{

}

#tags-filter-bottom {
  padding: 0.5em;
  border-radius: 3px;
  max-width: 500px;
  display: flex;
  max-height: 100px;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  border: 1px black solid;
  border-radius: 5px;
}
#tags-filter-bottom #tag-item{
  margin-top:3px;
}

#apply-filter {
  margin: 10px 20px;
  background-color: #d4d4d4;
  border: none;
}

#apply-filter:hover {
  background-color: #e7e7e7;
  border: none;
}

.loading-placeholder {
  height: 75vh;
}

@media (max-width: 620px) {
  .active-button {
    margin: 2px 2px;
    font-size: small;
    padding: 7px 7px;
  }
  .inactive-button {
    margin: 2px 2px;
    font-size: small;
    padding: 7px 7px;
  }
  #tags-filter-top #tag-item{
    font-size: smaller;
  }
  #tags-filter-top{
    max-width: 350px;
  }
  #tags-filter-bottom{
    max-width: 350px;
  }
  #tags-filter-bottom #tag-item{
    font-size: smaller;
  }

  #tags-filter-top #tag-item-clicked{
    font-size: smaller;
  }
  .modal-content {
    margin: 0% auto; /* 15% from the top and centered */
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 360px) {

  #slider-holder {
    margin: 0px 10px;
    min-width: 50%;
  }
}
