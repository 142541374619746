#visit{
    text-decoration: underline;
}

#pfp{
    height: 200px;
}
@media (max-width: 600px) {
    .profile-middle{
        display: block;
    }
    #pfp{
        height: 100px;
    }
    .profile-top{
        overflow:hidden;
    }
}