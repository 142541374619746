.create-image {
  width: 400px;
}

body {
  margin: 0;
  padding: 0;
}
.images {
}
.post-form {
  display: flex;
  flex-direction: column;
}
.create-holder {
  display: flex;
  justify-content: space-between;
  margin: 10px 100px 0px 20px;
}

.cropper {
  position: relative;
  height: 40vh;
  background-color: rgb(255, 0, 0);
}

.viewer {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#after-submit {
  display: flex;
  justify-content: center;
  margin: 20px 20px;
  text-align: left;
}
#after-submit-button {
  display: flex;
  justify-content: center;
  margin: 20px 30px 700px 30px;
}
.output {
  position: relative;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(91, 91, 91, 0.8);
}

.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

#share {
  font-style: normal;
  font-weight: 600;
  font-size: 0.7em;
}
.dev-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 30px 100px;
  background-color: rgb(216, 216, 216);
  height: 30px;
}
/* Links inside the navbar */
.dev-nav a {
  font-size: 16px;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

#input-group {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border: none;
}

#image {
  display: block;
  height: 200px;
}

button {
  padding: 10px 10px;
  box-sizing: border-box;
}
#back{
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  margin: 30px 0px;
}
#back:hover {
  background-color: rgb(106, 106, 106);
}
input {

}
select {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}
#category {
  max-width: 200px;
}
#sex {
  max-width: 200px;
}

#price {
  max-width: 200px;
}

.register-button-holder {
  display: flex;
  justify-content: left;
}
input[type=file]::file-selector-button {
  border: none;
  background-color: rgb(80, 80, 80);
  max-width: 200px;
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
}

input[type=file]::file-selector-button:hover {
  background-color: rgb(106, 106, 106);
}


#input-group label {
}
#bio {
  margin-left: 0px;
  padding: 12px 20px;
  box-sizing: border-box;
  resize: none;
}
.register-button{

  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  margin: 30px 0px;
}

.register-button:hover {
  background-color: rgb(106, 106, 106);
}




.register-form-holder {
  padding: 0px 30px;
}
.password-label {
  margin-top: 20px;
}
.password-label div {
  font-size: smaller;
}
