@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Inter:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap');* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;

}


.login-register {
  margin-left: 20px;
}

.featured-title {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  font-size: xx-large;
}

.delete-buttons{
  border: none;
  margin: 0;
  color: red;
  padding: 0;
}
.category {
  margin-right: 20px;
}

.loader{
  color: rgb(255, 255, 255);
  animation-name: loader;
  animation-duration: .75s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  0%  {color: white;}
  50% {color: black;}
  100% {color: white;}
}
