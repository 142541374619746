.form-holder {
  align-items: center;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  height: 100vh;
}

.form {
  background-color: #171717;
  border-radius: 5px;
  border-style: solid;
  box-sizing: border-box;
  margin: 0 20px;
  padding: 20px;
  width: 400px;
  margin-top: -10vh;
}

.title {
  color: #eee;
  font-style: italic;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}
.errors {
  color: #dc2f55;
}

.subtitle {
  color: #eee;

  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.subtitle-2 {
  color: #d7d7d7;

  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
}

.input-container {
  height: 40px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 30px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #453730;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  height: 100%;
  outline: 0;
  padding: 0px 40px;
  width: 100%;
}

.cut {
  background-color: #171717;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;

  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  outline: 0;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}

.button {
  background: rgb(255, 208, 0);
  background: linear-gradient(
    90deg,
    rgb(255, 208, 0) 0%,
    rgba(255, 58, 0, 1) 100%
  );
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}
