
.brand-card {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.1em;
  background-color: rgb(255, 255, 255);
  padding: 10px 10px;
  width: 90%;
}
.brand-info{
  display: flex;
  margin-top: 10px;
}

::-webkit-scrollbar {
  display: none;
}
.brand-card-bottom{
  display: flex;
  justify-content: space-between;
}
.pfp {
  height: 100px;
  margin: auto 0;
}
.brand-name{
  margin: auto 20px;
  font-size: larger;
}

.price {
  font-size: medium;
}
.minimal {
  font-size: smaller;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}



.image {
  width: 300px;
}
