.card {
  font-size: 1.1em;
  background-color: #ffffff;
  margin: 10px 10px;
  padding: 5px;
  border-radius: 5px;
}
#link{}

.post-tags-holder{
  height: 30px;
  overflow: scroll;
  display: flex;
  flex-wrap: nowrap;
}
.image{
  animation-name: image;
  animation-duration: .25s;
  animation-iteration-count: 1;
  width: 300px;
  height: 375px;
  border-radius: 5px;
}

@keyframes image {
  0%  {opacity: 0;}
  100% {opacity: 1;}
}
.post-price {
  font-size: large;
  font-weight: bold;
}
.minimal {
  font-size: small;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-text {
  padding: 0px 5px 5px 5px;
  text-align: left;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-bottom{
  display: flex;
  justify-content: space-between ;
  text-align: left;
}

.card-title{
  max-width: 290px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1100px) {
  .card{
    width: 220px;
    .image{
      width: 220px;
      height: 275px;
    }
  }
}

@media (max-width: 510px) {
  .card-text {
    font-size: .75em;
  }
  #user-link{
    font-size: 1em;
    overflow: hidden;
    max-width: 165px;
  }
  .card{
    margin: 5px 5px;
    padding: none;
    width: 165px;
    .image{
      width: 165px;
      height: 206.3px;
    }
  }
}


