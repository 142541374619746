
  .loader{
    color: rgb(255, 255, 255);
    animation-name: loader;
    animation-duration: .5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
  }
  
  
  @keyframes loader {
    0%  {color: white;}
    50% {color: black;}
    100% {color: white;}
  }
  