#tag-title {
  padding: 0.75em 1.25em;
  margin: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
  text-align: center;
}
#tag-title-holder{
  display: flex;
  justify-content: center;

}

#tag-title-text {
  cursor: pointer;
  font-size: xx-large;
}
