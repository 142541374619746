
body {
  font-family: "Space Grotesk", sans-serif;
  background-color: rgb(255, 255, 255);
  color: rgb(27, 27, 27);
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.current-posts{
  
}

@font-face {
  font-family: f;
  text-rendering: optimizeLegibility;
  src: url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
}
