.search-page{

}
.brand-card{
    margin: 20px auto;
}
#brand-image{
    width: 300px;
}

#brands-title{
    display: flex;
    padding: 0px 10px 0px 10px;
   
    margin: 25px 80px;
    font-size: xx-large;
}

#brands-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#brands-holder-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#brand-holder{
    display: flex;
    min-width: 95vw;
    flex-direction: column;
    background-color: rgb(212, 212, 212);
    justify-content: center;
    margin: 10px 40px;
    padding: 35px 0px 0px 0px;

}
@media (max-width: 600px) {
    #brands-title{
      justify-content: center;
      text-align: center; 
      font-size: 1.75em;
    }
    #brand-holder{
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 70vw;
        margin:5px;
        padding: 10px 10px;
    }
    
    #brands-holder{
        max-width: 300px;
    }
    
  }